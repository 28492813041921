

















































import { GroupInteraction, User } from '@/models';
import { PropType, computed, defineComponent, ref, watch } from '@/plugins/composition';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { mdiCheck, mdiCheckAll, mdiClose } from '@mdi/js';
import { storage } from '@/main';

export default defineComponent({
  name: 'GroupEmailInteractionListItem',
  props: {
    interaction: {
      type: Object as PropType<GroupInteraction>,
      required: true,
    },
    disableView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    const me = computed(() => storage.auth.state.user as User);
    const viewing = ref(false);
    const loading = ref(false);
    const previewHtml = ref('');
    const email = ref<GroupInteraction>();

    const loadPreview = async () => {
      loading.value = true;
      email.value = await GroupInteraction.find(props.interaction.uuid);
      loading.value = false;
    };

    const destroy = async (interaction: GroupInteraction): Promise<void> => {
      const confirm = await confirmation('Are you sure you want to delete this message?', {
        confirmType: 'warn',
      });

      if (confirm) {
        interaction.directDelete();

        emit('deleted');
      }
    };

    watch(viewing, async () => await loadPreview());

    return {
      me,
      emit,
      loading,
      viewing,
      previewHtml,
      destroy,
      email,
      mdiCheck,
      mdiCheckAll,
      mdiClose,
    };
  },
});
