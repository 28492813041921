import { render, staticRenderFns } from "./ClientEmailsPage.vue?vue&type=template&id=e79ff654&scoped=true&"
import script from "./ClientEmailsPage.vue?vue&type=script&lang=ts&"
export * from "./ClientEmailsPage.vue?vue&type=script&lang=ts&"
import style0 from "./ClientEmailsPage.vue?vue&type=style&index=0&id=e79ff654&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e79ff654",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VExpandTransition,VFadeTransition,VSkeletonLoader,VSpacer})
