




























































































import TextEditor from '@/components/inputs/TextEditor.vue';
import { Attachment, Client, Practitioner, Site } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { activeConversation } from '../useConversation';
import { formatISO } from 'date-fns';
import { mdiClockOutline } from '@mdi/js';

import AttachmentSelect from '@/components/inputs/AttachmentSelect.vue';
import DateTimePicker from '@/components/inputs/DateTimePicker.vue';
import SiteSelectInput from '@/components/inputs/SiteSelectInput.vue';
import UploadAttachmentsDialog from '@/domains/practitioner/modules/patients/components/UploadAttachmentsDialog.vue';
import { storage } from '@/main';

export default defineComponent({
  components: { TextEditor, AttachmentSelect, UploadAttachmentsDialog, DateTimePicker, SiteSelectInput },
  name: 'ComposeGroupEmail',
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const content = ref('');

    const sending = ref(false);
    const practitioner = ref<Practitioner>();
    const sendAt = ref(new Date());
    const attaching = ref(false);
    const subject = ref('New Message from HPWHI');
    const attachDialog = ref(false);
    const attachments = ref<Attachment[]>([]);
    const menu = ref(false);
    const selectingSite = ref(false);
    const selectedSite = ref<Site | null>(null);

    onMounted(async () => {
      practitioner.value = (await storage.auth.practitioner()) as Practitioner;
    });

    const to = computed(() => ({
      name: activeConversation.value?.patient?.name,
      email: activeConversation.value?.patient?.data.email,
    }));

    const send = async (): Promise<void> => {
      if (!practitioner.value) {
        return;
      }
      sending.value = true;

      const target = selectedSite.value ?? props.client;

      await target.groupInteractions().create({
        type: 'email',
        subject: subject.value,
        content: content.value,
        from: practitioner.value.uuid,
        sendAt: formatISO(sendAt.value instanceof Date ? sendAt.value : new Date()),
        attachments: attachments.value.map((item: Attachment) => item.data.uuid),
      });

      sending.value = false;
      sendAt.value = new Date();
      content.value = '';
      emit('sent');
    };

    const attachFiles = () => {
      attaching.value = true;
    };

    const setAttachments = (items: Attachment[]) => {
      attachments.value.push(...items);
    };

    const cancelSchedule = () => {
      menu.value = false;
      sendAt.value = new Date();
    };

    const selectSite = () => {
      selectingSite.value = true;
    };

    const sendToAllSites = () => {
      selectedSite.value = null;
      selectingSite.value = false;
    };

    return {
      content,
      send,
      attachFiles,
      activeConversation,
      subject,
      to,
      attachDialog,
      attachments,
      menu,
      sendAt,
      setAttachments,
      icons: {
        mdiClockOutline,
      },
      cancelSchedule,
      selectSite,
      selectingSite,
      selectedSite,
      sendToAllSites,
    };
  },
});
