




































import ComposeGroupEmail from '@/components/interactions/email-interaction/ComposeGroupEmail.vue';
import GroupEmailInteractionListItem from '@/components/interactions/email-interaction/GroupEmailInteractionListItem.vue';
import { Client, GroupInteraction } from '@/models';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { useLoader } from '@/composables/useLoader';
import { useRouter } from '@/router';

export default defineComponent({
  components: { GroupEmailInteractionListItem, ComposeGroupEmail },
  name: 'ClientEmailsPage',
  setup() {
    const client = ref<Client | null>(null);
    const interactions = ref<GroupInteraction[]>([]);
    const creating = ref(false);

    const { route, router } = useRouter();
    const { loading, withLoader } = useLoader();

    const fetchClient = async () => (client.value = await Client.find(route.value.params.uuid));

    const fetchInteractions = async () =>
      withLoader(async () => {
        if (!client.value) {
          return;
        }

        interactions.value = await client.value.groupInteractions().getAll();
      });

    onMounted(async () => {
      await fetchClient();
      await fetchInteractions();
    });

    const createEmail = () => {
      creating.value = true;
    };

    const handleSent = async () => {
      creating.value = false;
      await fetchInteractions();
    };

    return {
      client,
      interactions,
      fetchInteractions,
      createEmail,
      creating,
      handleSent,
      loading,
    };
  },
});
